<template>
    <div class="ApplicationdetailsT">
        <div class="ApplicationdetailsT_head ApplicationdetailsT_head_bg">
            <div class="th1 flex-align-center">{{ tableType[$route.query.rank] }}</div>
            <div class="th3 flex-align-center">应用总数</div>
            <div class="th2 flex-align-center">同步课程</div>
            <div class="th4 flex-align-center" v-if="dateKey == '20231' || dateKey == '20232'">每日一字</div>
            <div class="th4 flex-align-center" v-else>简洁版</div>
            <div class="th5 flex-align-center">硬笔体系课</div>
            <div class="th6 flex-align-center">作品投影</div>
            <div class="th7 flex-align-center">视频投影</div>
        </div>
        <div class="ApplicationdetailsT_box" v-if="ApplicationData.length != 0">
            <div class="ApplicationdetailsT_head Application_list" v-for="(item, index) in ApplicationData" :key="index">
                <!-- item.name, $route.query.rank != 2 ? item.areaId : item.schoolId)" -->
                <div class="th1 goprovince" :title="item.name" @click="goprovince(item)">{{ item.name
                }}</div>
                <div class="th3 flex-align-center">{{ item.applyNum || 0 }}</div>
                <div class="th2 flex-align-center">{{ item.syncCourse || 0 }}</div>
                <div class="th4 flex-align-center" v-if="dateKey == '20231' || dateKey == '20232'">{{ item.wordOfDay || 0 }}</div>
                <div class="th4 flex-align-center" v-else>{{ item.conciseCourse || 0 }}</div>
                <div class="th5 flex-align-center">{{ item.hardPenSys || 0 }}</div>
                <div class="th6 flex-align-center">{{ item.syncProject || 0 }}</div>
                <div class="th7 flex-align-center">{{ item.videoProject || 0 }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['ApplicationData', 'dateKey'],
    data() {
        return {
            Application_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
            tableType: ['市', '区县', '学校'],
            termId: '',
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        goprovince(item) {
            console.log('item', item);
            if (this.$route.query.rank == 0) {
                let routeData = this.$router.resolve({ path: '/CityData', query: { rank: 1, id: item.areaId, name: item.name, date: this.termId } });
                window.open(routeData.href, '_blank');
            } else if (this.$route.query.rank == 1) {
                let routeData = this.$router.resolve({ path: '/NewAreaData', query: { rank: 2, id: item.areaId, name: item.name, date: this.termId } });
                window.open(routeData.href, '_blank');
            } if (this.$route.query.rank == 2) {
                let routeData = this.$router.resolve({
                    path: '/dataScreenMain/schoolData', query: {
                        type: 'school',
                        role: 3,
                        bindId: item.schoolId,
                        name: item.name,
                        orgId: item.orgId,
                        date: this.termId
                    }
                });
                window.open(routeData.href, '_blank');
            }


        },
        //接收父组件传值
        getdate(value) {
            this.termId = value;
        }
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        //默认
        this.termId = this.dateKey;
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style lang="less" scoped>
@import "./less/ApplicationdetailsT.less";
</style>