<template>
    <div class="adhibitionTer" id="adhibitionTer"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['adhibitionTerName', 'adhibitionTerValue'],
    data() {
        return {

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        adhibitionTer_graphics() {
            this.$echarts.init(document.getElementById("adhibitionTer")).dispose();
            let that = this;
            let data = [];
            for (let index = 0; index < this.adhibitionTerValue.length; index++) {
                data.push({
                    value: this.adhibitionTerValue[index],
                    symbol: 'image://' + require("../../../assets/datascreen/provinceCityAreaData/fire" + (index + 1) + ".png")
                })

            };
            let myChart = this.$echarts.init(
                document.getElementById("adhibitionTer")
            );
            myChart.setOption({
                xAxis: {
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    }
                },
                yAxis: {
                    // data: ["平谷区第一小学三年级1班王老师", "平谷区第一小学三年级1班王老师", "平谷区第一小学三年级1班王老师", "平谷区第一小学三年级1班王老师", "平谷区第一小学三年级1班王老师", "平谷区第一小学三年级1班王老师", "平谷区第一小学三年级1班王老师", "平谷区第一小学三年级1班王老师", "平谷区第一小学三年级1班王老师", "平谷区第一小学三年级1班王老师"],
                    data: this.adhibitionTerName,
                    inverse: true,
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        // color: "#51a3ed",
                        color: "#fff",
                        fontSize: this.graphicRatio(12)
                    }
                },
                grid: {
                    top: "6%",
                    left: "5%",
                    ringht: "10%",
                    bottom: "6%",
                    containLabel: true,
                },
                series: [
                    {
                        type: "pictorialBar",
                        animation: true,
                        symbolRepeat: "fixed",
                        symbolClip: true,
                        symbolPosition: "start",
                        symbolOffset: [0, 0],
                        symbolSize: this.graphicRatio(18),
                        label: {
                            normal: {
                                show: true,
                                textStyle: {
                                    color: '#51a3ed',
                                    fontSize: this.graphicRatio(11),

                                },
                                position: "right",
                                offset: [this.graphicRatio(15), 0]

                            }
                        },
                        data: data,
                    },
                    {
                        type: "bar"
                    },
                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.adhibitionTer_graphics()
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped>
.adhibitionTer {
    width: 100%;
    height: 100%;
}
</style>