<template>
    <div class="provinceCityAreaData">
        <div class="head flex-align-between">
            <div class="filtrate"></div>
            <div class="screenTitle">
                {{ $route.query.name }}首师优字数据报告
            </div>
            <div class="filtrate flex-align-center">
                <div class="filtrate_son">
                    <el-select v-model="termValue" placeholder="请选择" :popper-append-to-body="false" size='small'
                        @change="termChange">
                        <el-option v-for="item in termList" :key="item.key" :label="item.label" :value="item.termName">
                        </el-option>
                    </el-select>
                </div>
                <div class="province_sel" v-if="$route.query.rank == 0">
                    <el-select v-model="cityValue" placeholder="请选择" :popper-append-to-body="false" size='small'
                        @change="provinceChange">
                        <el-option v-for="item in cityData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="province_sel" v-if="$route.query.rank == 1">
                    <el-select v-model="districtValue" placeholder="请选择" :popper-append-to-body="false" size='small'
                        @change="cityChange">
                        <el-option v-for="item in districtData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="province_sel" v-if="$route.query.rank == 2">
                    <el-select v-model="schoolValue" placeholder="请选择" :popper-append-to-body="false" size='small'
                        @change="districtChange">
                        <el-option v-for="item in schoolData" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="getback" v-if="statistics == 1" @click="getback">
                    首师优字
                </div>
            </div>
        </div>
        <div class="content">
            <div class="Lside">
                <div class="applctnfvrscrss">
                    <div class="diamond_title flex-align-center">各类课程应用情况</div>
                    <div class="primaryCoverage" v-if="pieValue.length != 0">
                        <Pie :pieValue="pieValue"></Pie>
                    </div>
                    <div class="primaryCoverage flex-align-center" v-else>
                        <PlaceholderScreen></PlaceholderScreen>
                    </div>

                </div>
                <div class="mnthlypplctnsttstcs">
                    <div class="diamond_title flex-align-center">每月应用统计</div>
                    <div class="primaryCoverage" v-if="bothLineName.length != 0">
                        <BothLine :bothLineName="bothLineName" :bothLineValue="bothLineValue"></BothLine>
                    </div>
                    <div class="primaryCoverage flex-align-center" v-else>
                        <PlaceholderScreen></PlaceholderScreen>
                    </div>
                </div>
                <div class="applctnsttstcsfrchgrd">
                    <div class="diamond_title flex-align-center">各年级应用统计</div>
                    <div class="primaryCoverage" v-if="Dbarvalue.length != 0">
                        <!-- <Dbar :Dbardata="Dbardata"></Dbar> -->
                        <Dbar :Dbarname="Dbarname" :Dbarvalue="Dbarvalue" :DbarmaxValue="DbarmaxValue"></Dbar>
                        <!-- <PictorialBar></PictorialBar> -->
                    </div>
                    <div class="primaryCoverage flex-align-center" v-else>
                        <PlaceholderScreen></PlaceholderScreen>
                    </div>
                </div>
            </div>
            <div class="middle">
                <div class="infrmtn">
                    <div class="circle-item circle-item-bg flex-column-center" v-for="(item, index) in infrmtnlist"
                        :key="index">
                        <span>{{ item.num + item.unit }}</span>
                        <span>{{ item.type }}</span>
                    </div>
                </div>
                <div class="applctnfcrstyps" v-if="$route.query.rank != 2">
                    <div class="diamond_title flex-align-between">
                        <div class="placeholder"></div>
                        <div>
                            {{ rankTitle1[$route.query.rank] }}
                        </div>
                        <div class="placeholder">
                            <img src="../../assets/datascreen/export.png" alt=""
                                @click="$route.query.rank != 2 ? getApplyDetailExcel(1) : getapplyTeaTop10Excel()" />
                            <span @click="$route.query.rank != 2 ? getApplyDetailExcel(1) : getapplyTeaTop10Excel()">
                                导出数据</span>
                        </div>
                    </div>
                    <div class="primaryCoverage" v-if="lessontypeName.length != 0">
                        <StackBar :lessontypeName="lessontypeName" :lessontypeValue="lessontypeValue"
                            :ApplicationMaxData="ApplicationMaxData" :termId="termId"></StackBar>
                    </div>
                    <div class="primaryCoverage flex-align-center" v-else>
                        <PlaceholderScreen></PlaceholderScreen>
                    </div>
                </div>
                <div class="Double_application flex-align-between" v-else>
                    <div class="sch_application">
                        <div class="diamond_title flex-align-between">
                            <div class="placeholder"></div>
                            <div> 各学校应用情况</div>
                            <div class="placeholder">
                                <img src="../../assets/datascreen/export.png" alt=""
                                    @click="getschoolApplySituationExcel" />
                                <span @click="getschoolApplySituationExcel"> 导出数据</span>
                            </div>
                        </div>
                        <div class="primaryCoverage" v-if="schoolApplyList.length != 0">
                            <div class="schyy_box">
                                <div class="schyy_head">
                                    <div class="th1 flex-align-center">学校</div>
                                    <div class="th2 flex-align-center">班级总数</div>
                                    <div class="th3 flex-align-center">应用班级数</div>
                                    <div class="th5 flex-align-center">教师总数</div>
                                    <div class="th6 flex-align-center">参与教师数</div>
                                </div>
                                <div class="schyy_list">
                                    <div class="schyy_list_son" v-for="(item, index) in schoolApplyList" :key="index">
                                        <div class="th1">{{ item.name }}</div>
                                        <div class="th2 flex-align-center" :title="item.countClass">{{ item.countClass }}
                                        </div>
                                        <div class="th3 flex-align-center">{{ item.applyClass }}</div>
                                        <div class="th5 flex-align-center">{{ item.countTeacher }}</div>
                                        <div class="th6 flex-align-center">{{ item.teaJoinNum }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="primaryCoverage flex-align-center" v-else>
                            <PlaceholderScreen></PlaceholderScreen>
                        </div>
                    </div>
                    <div class="tea_application">
                        <div class="diamond_title flex-align-between">
                            <div class="placeholder"></div>
                            <div> 应用最多的教师Top10</div>
                            <div class="placeholder">
                                <img src="../../assets/datascreen/export.png" alt="" @click="getapplyTeaTop10Excel" />
                                <span @click="getapplyTeaTop10Excel"> 导出数据</span>
                            </div>
                        </div>
                        <div class="primaryCoverage" v-if="adhibitionTer.length != 0">
                            <div class="ter_top_box">
                                <div class="ter_top_list flex-align" v-for="(item, index) in adhibitionTer" :key="index">
                                    <div class="ter_top_N flex-align">
                                        <div class="ter_top_item flex-align-center">{{ index + 1 }}</div>
                                        <div class="ter_top_schN text_sty">{{ item.schoolName }}</div>
                                        <div class="ter_top_clsN text_sty">{{ item.className }}</div>
                                        <div class="ter_top_terN text_sty">{{ item.teaName }}</div>
                                    </div>
                                    <div class="ter_top_num flex-align-center">{{ item.num }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="primaryCoverage flex-align-center" v-else>
                            <PlaceholderScreen></PlaceholderScreen>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Rside">
                <div class="applctndtls">
                    <div class="diamond_title flex-align-between">
                        <div class="placeholder"></div>
                        <div>
                            {{ rankTitle2[$route.query.rank] }}
                        </div>
                        <div class="placeholder">
                            <img src="../../assets/datascreen/export.png" alt="" @click="getApplyDetailExcel(2)">
                            <span @click="getApplyDetailExcel(2)"> 导出数据</span>
                        </div>
                    </div>
                    <div class="primaryCoverage" v-if="ApplicationData.length != 0">
                        <ApplicationdetailsT :ApplicationData="ApplicationData" :dateKey="termId" ref="ApplicationdetailsT">
                        </ApplicationdetailsT>
                    </div>
                    <div class="primaryCoverage flex-align-center" v-else>
                        <PlaceholderScreen></PlaceholderScreen>
                    </div>
                </div>
                <div class="evaluationScore">
                    <div class="diamond_title flex-align-between">
                        <div class="placeholder"></div>
                        <div> {{ rankTitle3[$route.query.rank] }}</div>
                        <div class="placeholder">
                            <img src="../../assets/datascreen/export.png" alt="" @click="getEvalScoreTOP10Excel" />
                            <span @click="getEvalScoreTOP10Excel"> 导出数据</span>
                        </div>
                    </div>
                    <div class="primaryCoverage" v-if="appraisalSchValue.length">
                        <AcrossBar :appraisalSchValue="appraisalSchValue" :appraisalSchName="appraisalSchName"></AcrossBar>
                        <!-- <MultidimensionalBar v-else></MultidimensionalBar> -->
                    </div>
                    <div class="primaryCoverage flex-align-center" v-else>
                        <PlaceholderScreen></PlaceholderScreen>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlaceholderScreen from '../DataScreen/placeholderBox/placeholderScreen.vue';
import Pie from './prvncndctyAssembly/pie.vue';
import BothLine from './prvncndctyAssembly/bothLine.vue';
import Dbar from './prvncndctyAssembly/3dbar.vue';
import StackBar from './prvncndctyAssembly/stackBar.vue';
import AcrossBar from './prvncndctyAssembly/acrossBar.vue';
import MultidimensionalBar from './prvncndctyAssembly/multidimensionalBar.vue';
import PictorialBar from './prvncndctyAssembly/pictorialBar.vue';
import AdhibitionTer from './prvncndctyAssembly/adhibitionTer.vue';
import ApplicationdetailsT from './prvncndctyAssembly/ApplicationdetailsT.vue';
export default {
    //import引入组件才能使用
    components: {
        PlaceholderScreen,
        Pie,
        BothLine,
        Dbar,
        StackBar,
        AcrossBar,
        MultidimensionalBar,
        PictorialBar,
        AdhibitionTer,
        ApplicationdetailsT
    },
    props: {},
    data() {
        return {
            //学期
            termList: [],
            termValue: '',
            termId: '',
            infrmtnlist: [
                {
                    num: '0',
                    type: '教师人数',
                    name: 'count_teacher',
                    unit: '人'
                },
                {
                    num: '0',
                    type: '上课总数',
                    name: 'tea_take',
                    unit: '节'
                },
                {
                    num: '0',
                    type: '学校总数',
                    name: 'count_school',
                    unit: '所'
                },
                {
                    num: '0',
                    type: '学生人数',
                    name: 'count_student',
                    unit: '人'
                },
                {
                    num: '0',
                    type: '学习课程',
                    name: 'stu_take',
                    unit: '节'
                },
                {
                    num: '0',
                    type: '测评总人次',
                    name: 'cp_all',
                    unit: '次'
                },
            ],
            cityData: [
                {
                    id: '0',
                    name: '全省'
                },
            ],
            cityValue: '全省',
            districtData: [
                {
                    id: '0',
                    name: '全市'
                },
            ],
            districtValue: '全市',
            schoolData: [
                {
                    id: '0',
                    name: '全区'
                },
            ],
            schoolValue: '全区',
            routeType: true,
            rankTitle1: ["各市不同课型应用情况", "各区县不同课型应用情况", "应用最多的教师Top10"],
            rankTitle2: ["各市应用详情", "各区县应用详情", "各学校应用详情"],
            rankTitle3: ["各市测评成绩Top10", "区县测评成绩Top10", "测评成绩Top10学校"],
            spreadhead: ['河北省首师优字数据报告', '唐山市首师优字数据报告', '平谷区首师优字数据报告'],
            //各类课程应用情况
            pieValue: [],
            //每月应用统计
            bothLineName: [],
            bothLineValue: [],
            //各年级应用统计
            Dbarname: [],
            Dbarvalue: [],
            DbarmaxValue: [],
            //应用最多的教师Top10
            adhibitionTer: [],
            adhibitionTerName: [],
            adhibitionTerValue: [],
            //测评成绩TOP10学校
            appraisalSchValue: [],
            appraisalSchName: [],

            //应用详情-不同课型应用情况
            ApplicationData: [],
            // 不同课型应用情况
            lessontypeName: [],
            lessontypeValue: {
                data1: [],
                data2: [],
                data3: [],
                data4: [],
            },
            ApplicationMaxData: [],
            statistics: '',
            schoolApplyList: [],

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        handleClick() {
            this.$router.push({
                path: "/CityData",
            })
        },
        statistics_Excel() {

        },
        provinceChange(value) {
            let name = this.cityData.find(option => option.id === value).name;
            if (name != '全省') {
                let routeData = this.$router.resolve({ path: '/CityData', query: { rank: 1, id: value, name: name, date: this.termId } });
                window.open(routeData.href, '_blank');
            }

        },
        cityChange(value) {
            let name = this.districtData.find(option => option.id === value).name;
            if (name != '全市') {
                let routeData = this.$router.resolve({ path: '/NewAreaData', query: { rank: 2, id: value, name: name, date: this.termId } });
                window.open(routeData.href, '_blank');
            }

        },
        districtChange(value) {
            let name = this.schoolData.find(option => option.id === value).name;
            let orgId = this.schoolData.find(option => option.id === value).orgId;
            console.log(value, name, orgId);
            if (name != '全区') {
                let routeData = this.$router.resolve({
                    path: '/dataScreenMain/schoolData', query: {
                        type: 'school',
                        role: 3,
                        bindId: value,
                        name: name,
                        orgId: orgId,
                        date: this.termId
                    }
                });
                window.open(routeData.href, '_blank');
            }
        },
        //获取学年学期列表 this.$Api.DataScreen
        getTermList() {
            this.$Api.DataScreen.queryTermList()
                .then(res => {
                    this.termList = res.data;
                    if (this.$route.query.date) {
                        const index = this.termList.findIndex(item => item.key == Number(this.$route.query.date));
                        this.termValue = this.termList[index].termName;
                        this.termId = this.termList[index].key;
                    } else {
                        this.termValue = this.termList[0].termName;
                        this.termId = this.termList[0].key;
                    }
                    this.apilist();
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        termChange(value) {
            this.termId = this.termList.find(option => option.termName === value).key;
            this.$refs.ApplicationdetailsT.getdate(this.termId);
            this.apilist();
        },
        //请求接口集合
        apilist() {
            //区县请求学校接口
            this.$route.query.rank != 2 ? this.getarea() : this.getSchoolList();
            //基础数据
            this.basicsStatistics(3);
            //各类课程应用情况
            this.basicsStatistics(7);
            //获取每月应用统计
            this.getapplyStatByMonth();
            //各年级应用统计
            this.getapplyStatByGrade();
            //应用最多的教师Top10
            if (this.$route.query.rank == 2) {
                this.getapplyTeaTop10();
                this.getschoolApplySituation();
            }
            // this.$route.query.rank == 2 ? this.getapplyTeaTop10() : '';
            //测评成绩TOP10学校(省-市-区县)
            this.getEvalScoreTOP10();
            // 查询 各地区或学校应用详情
            this.getApplyDetail();

        },
        //获取地区
        async getarea() {
            this.cityData = [
                {
                    id: '0',
                    name: '全省'
                },
            ];
            this.districtData = [
                {
                    id: '0',
                    name: '全市'
                },
            ];
            let data = {
                pid: this.$route.query.id,
                level: Number(this.$route.query.rank) + 1,
            };
            let resData = await this.$Api.DataScreen.getarea(data);
            if (this.$route.query.rank == 0) {
                this.cityData = [...this.cityData, ...resData.data];
            } else {
                this.districtData = [...this.districtData, ...resData.data];
            }
        },
        //获取区域学校
        async getSchoolList() {
            this.schoolData = [
                {
                    id: '0',
                    name: '全区'
                },
            ];
            let data = {
                termKey: this.termId,
                districtId: this.$route.query.id,
            };
            let resData = await this.$Api.DataScreen.getSchoolList(data);
            this.schoolData = [...this.schoolData, ...resData.data];
        },
        //获取每月应用统计
        async getapplyStatByMonth() {
            this.bothLineName = [];
            this.bothLineValue = [];
            let data = {
                termKey: this.termId,
                level: this.$route.query.rank,
                areaId: this.$route.query.id,
            };
            let resData = await this.$Api.DataScreen.getapplyStatByMonth(data);
            resData.data.forEach(element => {
                this.bothLineName.push(
                    element.attId + '月'
                )
                this.bothLineValue.push(
                    element.count
                )
            });
        },
        //各年级应用统计
        async getapplyStatByGrade() {
            this.Dbarname = [];
            this.Dbarvalue = [];
            this.DbarmaxValue = [];
            let data = {
                termKey: this.termId,
                level: this.$route.query.rank,
                areaId: this.$route.query.id,
            };
            let resData = await this.$Api.DataScreen.getapplyStatByGrade(data);
            resData.data.forEach(element => {
                this.Dbarname.push(element.indexRank + '年级');
                this.Dbarvalue.push(element.result);
            });
            resData.data.forEach(element => {
                this.DbarmaxValue.push(Math.max(...this.Dbarvalue) + Math.max(...this.Dbarvalue) * 0.2);
            });
        },
        //应用最多的教师Top10
        async getapplyTeaTop10() {
            this.adhibitionTerName = [];
            this.adhibitionTerValue = [];
            this.adhibitionTer = [];
            let data = {
                termKey: this.termId,
                areaId: this.$route.query.id,
            };
            let resData = await this.$Api.DataScreen.getapplyTeaTop10(data);
            let newData = resData.data.map(item => ({
                ...item,
                schoolName: item.schoolName.startsWith('北京市') ? item.schoolName.slice(3) : item.schoolName,
            }));
            this.adhibitionTer = newData;
            this.adhibitionTerName = newData.slice(0, 10).map(item => item.schoolName + ' '
                + item.className + ' ' + item.teaName);
            this.adhibitionTerValue = newData.slice(0, 10).map(item => item.num);
        },
        //测评成绩TOP10学校
        async getEvalScoreTOP10() {
            this.appraisalSchValue = [];
            this.appraisalSchName = [];
            let data = {
                termKey: this.termId,
                level: this.$route.query.rank,
                areaId: this.$route.query.id,
            };
            let resData = await this.$Api.DataScreen.getEvalScoreTOP10(data);
            if (resData.data.length == 0) return false;
            let newData = resData.data.map(item => ({
                ...item,
                name: item.name.startsWith('北京市') && this.$route.query.rank == 2 ? item.name.slice(3) : item.name,
            }));
            newData.map(item => {
                if (item.r == 1) {
                    this.appraisalSchValue.push(1 * 100)
                } else {
                    let num = item.r.toFixed(4)
                    this.appraisalSchValue.push(this.toPercentage(num * 100))
                }
            });
            this.appraisalSchName = newData.map(item => item.name);
        },
        toPercentage(num, decimalPlaces = 2) {
            const roundedNum = num.toFixed(decimalPlaces);
            return `${roundedNum}`;
        },
        //查询 各学校应用详情
        async getApplyDetail() {
            this.lessontypeName = [];
            this.ApplicationData = [];
            this.lessontypeValue = {
                data1: [],
                data2: [],
                data3: [],
                data4: [],
            };
            this.ApplicationMaxData = [];
            let data = {
                termKey: this.termId,
                level: this.$route.query.rank,
                areaId: this.$route.query.id,
            };
            let resData = await this.$Api.DataScreen.getApplyDetail(data);
            if (resData.data.length == 0) return false;
            this.ApplicationData = resData.data.map(item => ({
                ...item,
                name: item.name.startsWith('北京市') && this.$route.query.rank == 2 ? item.name.slice(3) : item.name,
            }));
            let maxdata = [];
            this.ApplicationData.forEach(item => {
                if (this.termId == '20231' || this.termId == '20232') {
                    maxdata.push(item.syncCourse + item.wordOfDay + item.hardPenSys + item.softPenSys)
                    this.lessontypeValue['data2'].push(item.wordOfDay || 0);
                } else {
                    maxdata.push(item.syncCourse + item.conciseCourse + item.hardPenSys + item.softPenSys)
                    this.lessontypeValue['data2'].push(item.conciseCourse || 0);
                }
                this.lessontypeName.push(item.name);
                this.lessontypeValue['data1'].push(item.syncCourse || 0);
                this.lessontypeValue['data3'].push(item.hardPenSys || 0);
                this.lessontypeValue['data4'].push(item.softPenSys || 0);
            });
            resData.data.forEach(item => {
                this.ApplicationMaxData.push(Math.max(...maxdata));
            })
        },
        //基础数据
        async basicsStatistics(chartid) {
            this.infrmtnlist.map((item, index) => {
                item.num = 0;
            });
            this.pieValue = [];
            let data = {
                chart: chartid,
                level: Number(this.$route.query.rank) + 2,
                termKey: this.termId,
                bindId: this.$route.query.id,
            };
            let resData = await this.$Api.DataScreen.basicsStatistics(data);

            if (chartid == 3) {
                this.infrmtnlist.map((item, index) => {

                    if (resData.data[item.name]) {
                        if (item.name == 'count_teacher' || item.name == 'count_school' || item.name == 'count_student') {
                            item.num = resData.data[item.name].result
                        } else {
                            item.num = resData.data[item.name].count;
                        }
                    } else {
                        item.num = 0;
                    }
                    // item.num = resData.data[item.name] ? resData.data[item.name].count : 0;
                })
            } else if (chartid == 7) {
                for (let key in resData.data) {
                    this.pieValue.push(
                        { value: resData.data[key].count, name: resData.data[key].name }
                    )
                }
                if (this.termId == '20231' || this.termId == '20232') {
                    this.pieValue = this.pieValue.filter(item => item.name !== '同步投影' && item.name !== '视频投影' && item.name !== '简洁版');
                } else {
                    this.pieValue = this.pieValue.filter(item => item.name !== '同步投影' && item.name !== '视频投影' && item.name !== '每日一字');
                }
            }
        },
        //教师应用详情Excel导出
        getApplyDetailExcel(type) {
            let datas = {
                termKey: this.termId,
                level: this.$route.query.rank,
                areaId: this.$route.query.id,
            };
            this.$Api.DataScreen.getApplyDetailExcel(datas)
                .then(res => {

                    const link = document.createElement('a')
                    const blob = new Blob([res], {
                        type: 'application/x-msdownload'
                    });
                    const fileName = type == 1 ? this.rankTitle1[this.$route.query.rank] : this.rankTitle2[this.$route.query.rank];
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.download = fileName + '.xls';
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
        //获取区域和学校测评成绩TOP10统计表数据Excel
        getEvalScoreTOP10Excel() {
            let datas = {
                termKey: this.termId,
                level: this.$route.query.rank,
                areaId: this.$route.query.id,
            };
            this.$Api.DataScreen.getEvalScoreTOP10Excel(datas)
                .then(res => {
                    const link = document.createElement('a')
                    const blob = new Blob([res], {
                        type: 'application/x-msdownload'
                    });
                    const fileName = this.rankTitle3[this.$route.query.rank];
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.download = fileName + '.xls';
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
        //应用最多的教师Top10Excel
        getapplyTeaTop10Excel() {
            let datas = {
                termKey: this.termId,
                areaId: this.$route.query.id,
            };
            this.$Api.DataScreen.getapplyTeaTop10Excel(datas)
                .then(res => {
                    const link = document.createElement('a')
                    const blob = new Blob([res], {
                        type: 'application/x-msdownload'
                    });
                    const fileName = this.rankTitle1[this.$route.query.rank];
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.download = fileName + '.xls';
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
        getback() {
            this.$router.push("/home");
        },
        // 各学校应用情况
        async getschoolApplySituation() {
            this.schoolApplyList = [];
            let data = {
                termKey: this.termId,
                level: this.$route.query.rank,
                areaId: this.$route.query.id,
            };
            let resData = await this.$Api.DataScreen.getschoolApplySituation(data);
            this.schoolApplyList = resData.data;
        },
        // 判断是否项目校
        async getqueryUserMaxOrg() {
            let resData = await this.$Api.Home.queryUserMaxOrg();
            this.statistics = resData.data.statistics;
        },
        //各学校应用情况导出Excel
        getschoolApplySituationExcel() {
            let datas = {
                termKey: this.termId,
                level: this.$route.query.rank,
                areaId: this.$route.query.id,
            };
            this.$Api.DataScreen.getschoolApplySituationExcel(datas)
                .then(res => {
                    const link = document.createElement('a')
                    const blob = new Blob([res], {
                        type: 'application/x-msdownload'
                    });
                    const fileName = '各学校应用情况';
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.download = fileName + '.xls';
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        //学期请求
        this.getTermList();
        this.getqueryUserMaxOrg();


    },
    beforeCreate() {

    },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style lang="less" scoped>
@import "./less/provinceCityAreaData.less";
</style>