<template>
    <div class="acrossBar" id="acrossBar"></div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['appraisalSchValue','appraisalSchName'],
    data() {
        return {

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        acrossBar_graphics() {
            this.$echarts.init(document.getElementById("acrossBar")).dispose();
            let that = this;
            var obj = {
                ydata: this.appraisalSchName,
                data: {
                    data1: this.appraisalSchValue,
                },
            };
            let myChart = this.$echarts.init(
                document.getElementById("acrossBar")
            );

            myChart.setOption({
                //图标离容器的距离
                grid: {
                    left: '3%',
                    // right: '5%',
                    right: '15%',
                    bottom: '5%',
                    top: '8%',
                    containLabel: true,
                },
                //x坐标轴
                xAxis: [
                    {
                        type: 'value',
                        axisTick: {
                            show: false,
                        },
                        boundaryGap: true,
                        axisLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],

                //y坐标轴
                yAxis: [
                    {
                        type: 'category',
                        data: obj.ydata,
                        splitLine: {
                            show: false,
                        },
                        inverse: true,
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#2e76c4",
                                width: 1,
                                type: 'solid',
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#fff',
                                fontSize: this.graphicRatio(12),
                            },
                        },
                    },
                ],
                // 数据展示区域
                series: [
                    {
                        // name: '同步课程',
                        type: 'bar',
                        itemStyle: {
                            barBorderRadius: [0, 20, 20, 0],
                        },
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                            offset: 0,
                            color: 'rgba(14, 40, 88,0.1)'
                        },
                        {
                            offset: 0.5,
                            color: 'rgba(34, 139, 131,1)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(45, 209, 165,1)'
                        }
                        ]),
                        barWidth: this.graphicRatio(16),
                        label: {
                            show: true,
                            position: 'outside',
                            color: '#fff',
                            fontSize: this.graphicRatio(12),
                            formatter: (params) => {
                                return params.value + '%';
                            },
                        },
                        data: obj.data.data1,
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.acrossBar_graphics()
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped>
.acrossBar {
    width: 100%;
    height: 100%;
}
</style>